import Axios, {AxiosRequestConfig} from 'axios';
import ServerMessageListDto from '@/logic/ark-stats-backend/dto/servermessage/ServerMessageListDto';
import {JsonConvert, OperationMode, ValueCheckingMode} from 'json2typescript';
import ServerMessageDto from '@/logic/ark-stats-backend/dto/servermessage/ServerMessageDto';
import RconMessageLogDto from "@/logic/ark-stats-backend/dto/rconmessagelog/RconMessageLogDto";
import RconMessageLogListDto from "@/logic/ark-stats-backend/dto/rconmessagelog/RconMessageLogListDto";

export interface StructuresDto {
    data: StructureDto[];
}

export interface LocationDto {
    x: number;
    y: number;
    z: number;
}

export interface StructureDto {
    id: string;
    ownerId: number;
    serverId: number;
    type: string;
    ownerName: string;
    count: number;
    lastInAllyRange: Date;
    created: Date;
    updated: Date;
    minLocation: LocationDto;
    maxLocation: LocationDto;
}

export interface BuildingDto {
    id: string;
    ownerId: number;
    serverId: number;
    count: number;
    lastInAllyRange: Date;
    created: Date;
    updated: Date;
    minLocation: LocationDto;
    maxLocation: LocationDto;
    countByType: { string: number };
}

export interface PlayersDto {
    data: PlayerDto[];
}

export interface PlayerDto {
    id?: string;
    serverId?: number;
    steam?: SteamAccountDto;
    character?: CharacterDto;
}

export interface SteamAccountDto {
    id?: string;
    name?: string;
}

export interface CharacterDto {
    id?: number;
    name?: string;
    tribeId?: number;
    spawnDay?: number;
    spawnTime?: number;
    level?: number;
    statLevel?: StatsDto;
}

export interface StatsDto {
    Oxygen?: number;
    Weight?: number;
    Stamina?: number;
    Damage?: number;
    Speed?: number;
    HP?: number;
    Crafting?: number;
    Food?: number;
}

export interface TribeDto {
    id?: string;
    tribeId?: number;
    serverId?: number;
    name?: string;
    owner?: number;
    members?: number[];
    updated?: Date;
    modified?: Date;
}

export interface ServerMessage {
    id?: string;
    message?: string;
    from?: Date;
    to?: Date;
    serverIds?: string[];
    disabled?: boolean;
}

export interface ResourceListDto<T> {
    data: T[];
    start: number;
    limit: number;
    count: number;
}

export interface TribeLogDto {
    id: string;
    tribeId: number;
    serverId: number;
    day: number;
    time: string;
    message: string;
    created: Date;
}

export interface DinoStatValuesDto {
    level: number;
    health: number;
    stamina: number;
    weight: number;
    crafting: number;
    food: number;
    damage: number;
    speed: number;
    oxygen: number;
}

export interface DinoStatsDto {
    base: DinoStatValuesDto;
    extra: DinoStatValuesDto;
    values: DinoStatValuesDto;
}

export interface DinoColorsDto {
    r0: number;
    r1: number;
    r2: number;
    r3: number;
    r4: number;
    r5: number;
}

export interface ImprintedByDto {
    playerId: number;
    quality: number;
}

export interface MutationsDto {
    father: number;
    mother: number;
}

export interface OwnerDto {
    className: string;
    tribeId: number;
    playerId: number;
    lastInAllyRange: Date;
}

export interface InventoryDto {
    className?: string;
    tribeId?: number;
    lastInAllyRange?: Date;
    dinoId?: string;
    location?: LocationDto;
}

export interface ItemDto {
    id?: string;
    serverId?: number;
    id1?: number;
    id2?: number;
    type?: string;
    quantity?: number;
    unique?: boolean;
    blueprint?: boolean;
    canSlot?: boolean;
    inventory?: InventoryDto;
}

export interface PurgeFlagDto {
    id?: string;
    serverId?: number;
    location: LocationDto;
    color1?: number;
    color2?: number;
    color3?: number;
    color4?: number;
    color5?: number;
    color6?: number;
    ownerId: number;
    created?: Date;
    updated?: Date;
}

export interface DinoDto {
    id?: string;
    id1?: number;
    id2?: number;
    name?: string;
    female?: boolean;
    type?: string;
    serverId?: number;
    stats?: DinoStatsDto;
    colors?: DinoColorsDto;
    location: LocationDto;
    died?: Date;
    tamed?: Date;
    imprint?: ImprintedByDto;
    updated?: Date;
    mutations?: MutationsDto;
    aggressionLevel?: number;
    fatherId?: string;
    motherId?: string;
    following?: boolean;
    inCryo?: boolean;
    mating?: boolean;
    tamedOnServerName?: string;
    owner?: OwnerDto;
}

export interface LogDto {
    id: string;
    serverId: number;
    logFile: string;
    text: string;
    created: Date;
}

const jsonConvert: JsonConvert = new JsonConvert();
jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
jsonConvert.ignoreRequiredCheck = false;
jsonConvert.operationMode = OperationMode.ENABLE;

export abstract class ArkStatsBackendClient {
    private static AXIOS = Axios.create();
    private static BASE_URL = 'https://ark-stats-backend.domination-gaming.com/';

    static async get<T>(url: string): Promise<T> {
        const config: AxiosRequestConfig = {
            withCredentials: true
        }
        const response = await this.AXIOS.get<T>(`${this.BASE_URL}${url}`, config);
        return response.data;
    }

    static async getGenericObject<T>(type: string, options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<T> {
        const parameters = [];
        if (options.filter) {
            parameters.push(`filter=${encodeURIComponent(options.filter)}`);
        }
        if (options.fields) {
            parameters.push(`fields=${options.fields.map(encodeURIComponent).join(',')}`);
        }
        if (options.limit) {
            parameters.push(`limit=${encodeURIComponent(options.limit)}`);
        }
        if (options.orderBy) {
            parameters.push(`sort=${encodeURIComponent(options.orderBy)}`);
        }

        const url = `${type}?${parameters.join('&')}`;
        return (await this.get(url));
    }

    static async getItems(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<ItemDto>> {
        return (await this.getGenericObject('item', options));
    }

    static async getStructures(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<StructuresDto> {
        return (await this.getGenericObject('structures', options));
    }

    static async getTribes(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<TribeDto>> {
        return (await this.getGenericObject('tribe', options));
    }

    static async getPlayers(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<PlayersDto> {
        return (await this.getGenericObject('player', options));
    }

    static async getTribeLogs(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<TribeLogDto>> {
        return (await this.getGenericObject('tribelog', options));
    }

    static async getDinos(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<DinoDto>> {
        return (await this.getGenericObject('dino', options));
    }

    static async getBuildings(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<BuildingDto>> {
        return (await this.getGenericObject('building', options));
    }

    static async getPurgeFlags(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ResourceListDto<PurgeFlagDto>> {
        return (await this.getGenericObject('purgeflags', options));
    }

    static async getGenericValues<T>(type: string, field: string, filter?: string, order?: string): Promise<T[]> {
        const parameters = [];
        parameters.push(`field=${encodeURIComponent(field)}`);
        if (filter) {
            parameters.push(`filter=${encodeURIComponent(filter)}`);
        }
        if (order) {
            parameters.push(`order=${encodeURIComponent(order)}`);
        }
        const url = `https://ark-stats-backend.domination-gaming.com/${type}/values?${parameters.join('&')}`;
        const config: AxiosRequestConfig = {
            withCredentials: true
        }

        const response = await this.AXIOS.get<T[]>(url, config);
        return response.data;
    }

    static async getStructureValues<T>(field: string, filter?: string, order?: string): Promise<T[]> {
        return await this.getGenericValues<T>('structures', field, filter, order);
    }

    static async getTribeValues<T>(field: string, filter?: string, order?: string): Promise<T[]> {
        return await this.getGenericValues<T>('tribe', field, filter, order);
    }

    static async getTribeLogValues<T>(field: string, filter?: string, order?: string): Promise<T[]> {
        return await this.getGenericValues<T>('tribelog', field, filter, order);
    }

    static async getAggregate(type: string, options: { filter?: string; groupBy?: string[]; fields?: string[]; order?: string[] }): Promise<any> {
        const parameters = [];
        if (options.fields) {
            parameters.push(`fields=${options.fields.map(encodeURIComponent).join(',')}`);
        }
        if (options.groupBy) {
            parameters.push(`groupBy=${options.groupBy.map(encodeURIComponent).join(',')}`);
        }
        if (options.order) {
            parameters.push(`sort=${options.order.map(encodeURIComponent).join(',')}`);
        }
        if (options.filter) {
            parameters.push(`filter=${encodeURIComponent(options.filter)}`);
        }

        const url = `https://ark-stats-backend.domination-gaming.com/${type}/aggregate?${parameters.join('&')}`;
        const config: AxiosRequestConfig = {
            withCredentials: true
        }

        const response = await this.AXIOS.get(url, config);
        return response.data;
    }

    static async getItemsAggregate(options: { filter?: string; groupBy?: string[]; fields?: string[]; order?: string[] }): Promise<any> {
        return this.getAggregate("item", options);
    }

    static async getDinosAggregate(options: { filter?: string; groupBy?: string[]; fields?: string[]; order?: string[] }): Promise<any> {
        return this.getAggregate("dino", options);
    }

    static async getServerMessages(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<ServerMessageListDto> {
        const json = (await this.getGenericObject<string>('servermessage', options));
        return jsonConvert.deserializeObject(json, ServerMessageListDto);
    }

    static async putServerMessage(id: string, serverMessage: ServerMessageDto | null): Promise<ServerMessageDto> {
        const url = `https://ark-stats-backend.domination-gaming.com/servermessage/${encodeURI(id)}`;
        const response = await this.AXIOS.put(url, jsonConvert.serializeObject(serverMessage, ServerMessageDto),
            {headers: {'content-type': 'application/json'}, withCredentials: true});
        return response.data;
    }

    static async postServerMessage(serverMessage: ServerMessageDto | null): Promise<ServerMessageDto> {
        const url = `https://ark-stats-backend.domination-gaming.com/servermessage/`;
        const response = await this.AXIOS.post(url, jsonConvert.serializeObject(serverMessage, ServerMessageDto),
            {headers: {'content-type': 'application/json'}, withCredentials: true});
        return response.data;
    }

    static async deleteServerMessage(id: string): Promise<ServerMessageDto> {
        const url = `https://ark-stats-backend.domination-gaming.com/servermessage/${encodeURI(id)}`;
        const response = await this.AXIOS.delete(url,
            {headers: {'content-type': 'application/json'}, withCredentials: true});
        return response.data;
    }

    static async getRconMessageLog(options: { filter?: string; fields?: string[]; orderBy?: string; limit?: number }): Promise<RconMessageLogListDto> {
        const json = (await this.getGenericObject<string>('rcon', options));
        return jsonConvert.deserializeObject(json, RconMessageLogListDto);
    }

    static async sendRcon(serverId: string, command: string): Promise<RconMessageLogDto> {
        const url = `https://ark-stats-backend.domination-gaming.com/rcon/${serverId}`;
        const response = await this.AXIOS.post<string>(url, {
            command: command
        }, {headers: {'content-type': 'application/json'}, withCredentials: true});
        return jsonConvert.deserializeObject(response, RconMessageLogDto);
    }
}
