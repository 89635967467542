import {JsonObject, JsonProperty} from 'json2typescript';
import DateConverter from '@/logic/converter/DateConverter';

@JsonObject('RconMessageLogDto')
export default class RconMessageLogDto {

    @JsonProperty('id', String, true)
    id: string | null = null;

    @JsonProperty('serverId', String, true)
    serverId: string | null = null;

    @JsonProperty('executorDiscordId', String, true)
    executorDiscordId: string | null = null;

    @JsonProperty('created', DateConverter, true)
    created: Date | null = null;

    @JsonProperty('executed', DateConverter, true)
    executed: Date | null = null;

    @JsonProperty('command', String, true)
    command: string | null = null;

    @JsonProperty('result', String, true)
    result: string | null = null;

}
