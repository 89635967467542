import {JsonObject, JsonProperty} from 'json2typescript';
import DateConverter from '@/logic/converter/DateConverter';

@JsonObject('ServerMessageDto')
export default class ServerMessageDto {

    @JsonProperty('id', String, true)
    id: string | null = null;

    @JsonProperty('message', String, true)
    message: string | null = null;

    @JsonProperty('from', DateConverter, true)
    from: Date | null = null;

    @JsonProperty('to', DateConverter, true)
    to: Date | null = null;

    @JsonProperty('disabled', Boolean, true)
    disabled = false;

    @JsonProperty('serverIds', [String], true)
    serverIds: string[] = [];

}
