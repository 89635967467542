import {JsonConverter, JsonCustomConvert} from 'json2typescript';
import moment from 'moment';

@JsonConverter
export default class DateConverter implements JsonCustomConvert<Date> {

    public serialize(date: Date): string {
        return moment(date).toISOString(false);
    }

    public deserialize(dateString: string): Date {
        return moment(dateString).toDate();
    }
}
